import { useTheme } from "@material-ui/styles";
import React from "react";

type Props = { rating: number; style?: any };
const Badge: React.FC<Props> = ({ rating, style }) => {
  const theme = useTheme() as any;
  const getBadgeColor = (score: number) => {
    if (score > 60) return theme.palette.success;
    if (score > 45) return theme.palette.warning;
    return theme.palette.error;
  };
  return (
    <div
      style={{
        padding: "7px 15px",
        textAlign: "center",
        borderRadius: 3,
        backgroundColor: getBadgeColor(rating).main,
        ...style
      }}
    >
      {rating}
    </div>
  );
};

export default Badge;
