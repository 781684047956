import React from "react";
import { FilmListProps } from "../types";
import Badge from "./Badge";

const FilmList: React.FC<FilmListProps> = ({ data }) => {
  return (
    <div>
      {data.allFilmCsv.nodes.map((film: any) => (
        <div
          key={film.imdb}
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "10px 0px"
          }}
        >
          <Badge rating={film.rating} />
          <span style={{ textAlign: "center", padding: "7px 3px" }}>
            {film.eTitle === film.oTitle
              ? film.eTitle
              : `${film.eTitle} (${film.oTitle})`}{" "}
            {film.rewatched === "yes" ? "⇆" : ""} ({film.year}, {film.directors.split(", ").reduce((t: string, d: string, i: number) => t + (i < 2 ? d.trim() + (i===film.directors.split(", ").length-1 ? "" : ", ")  : i === 2 ? " et. al." : ""),"")}
            )
          </span>
        </div>
      ))}
    </div>
  );
};

export default FilmList;
