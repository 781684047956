import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";
import React from "react";

type Props = { navigatedTo?: true };
const GoBack: React.FC<Props> = ({ navigatedTo }) => {
  return (
    <Typography
      style={{ cursor: "pointer" }}
      onClick={() => (navigatedTo ? navigate(-1) : navigate("/"))}
    >
      <span style={{ position: "relative", top: "-0.1em" }}>←</span> back
    </Typography>
  );
};

export default GoBack;
