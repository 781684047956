import React from "react";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  Divider,
  makeStyles,
  Typography,
  Link as MuiLink
} from "@material-ui/core";
import GoBack from "../components/GoBack";
import FilmList from "../components/FilmList";

const useStyles = makeStyles(theme => ({
  link: {
    margin: theme.spacing(0, 1, 0, 1)
  },
  text: {
    alignText: "justify"
  },
  divider: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  img: { width: 200, float: "right", margin: theme.spacing(0, 0, 2, 2) },
  space: { height: theme.spacing(2) }
}));

const FilmsPage: React.FC<any> = ({ data, location }) => {
  const classes = useStyles();
  return data ? (
    <Layout>
      <SEO
        title="All Films"
        description="List of all films I have seen in ranked order."
      />
      <GoBack navigatedTo={location?.state?.navigatedTo} />
      <Divider className={classes.divider} />
      <Typography variant="h4" component="h1">
        All Films Ranked
      </Typography>
      <Typography variant="body1">
        Idea stolen from{" "}
        <MuiLink href="https://www.panix.com/~dangelo/retrobygrade.html">
          Mike D'Angelo
        </MuiLink>
        . Only includes movies I have seen recently enough to tack on a
        100-point rating. Films marked with '⇆' I have seen at least twice.
      </Typography>
      <FilmList data={data} />
    </Layout>
  ) : null;
};

export const pageQuery = graphql`
  query FilmsPage {
    allFilmCsv {
      nodes {
        rating
        rewatched
        eTitle
        oTitle
        imdb
        year
        directors
      }
    }
  }
`;

export default FilmsPage;
